<template>
	<component :is="modal" />
</template>

<script>
	const ExcluirLista = () => import('@/components/modal/ExcluirLista.vue');
	const AtualizarLista = () => import('@/components/modal/AtualizarLista.vue');
	export default {
		name: 'Modal',
		components: {
			ExcluirLista,
			AtualizarLista,
		},
		computed: {
			modal() {
				return this.$store.state.modal;
			},
		},
	};
</script>
